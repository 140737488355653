<template>
  <div class="body">
    <!-- 导航栏 -->
    <Nav />
    <div class="content">
      <el-row class="title">
        <el-col :span="8">
          <img src="@/assets/img/icon/smile.png" alt="" />
          欢迎您登录导师使用平台
        </el-col>
       
        <el-col :span="2" :offset="14" class="icon">
          <div @click="logout">
            <img src="@/assets/img/icon/close.png" alt="" /> 退出
          </div>
        </el-col>
      </el-row>
      <router-view />
    </div>
    <div />
  </div>
</template>

<script>
import Nav from "@/components/nav";
import { mapState } from "vuex";

export default {
  name: "",
  components: {
    Nav,
  },
  computed: {
    ...mapState(["tabIndex"]),
  },
  data() {
    return {
      word: "",
    };
  },
  methods: {
    logout() {
      this.$alert("您确认要退出吗！", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            localStorage.clear();
            sessionStorage.clear();
            this.$router.push("/login");
          }
        },
      });
    },
    searchUser() {
      this.$router.push({
        path: "/user?type=1",
        query: { user_name: this.word },
      });
    },
  },
};
</script>
<style lang="less">
.page {
  margin-top: 5px;
  margin-bottom: 10px;

  .main {
    background: white;
    padding: 10px;
    margin-bottom: 5px;

    .title {
      border-left: 5px solid #5b97f9;
      text-indent: 1rem;
    }
  }
}
</style>

<style scoped lang='less'>
a {
  color: black;
}

.body {
  width: 1300px;
  margin: 0 auto;
  background: #ecf0f3;
}

.content {
  width: 1090px;
  margin-left: 8px;
  vertical-align: top;
  display: inline-block;

  .title {
    width: 100%;
    background: white;
    line-height: 50px;
    height: 50px;
    padding-left: 20px;

    img {
      position: relative;
      top: 3px;
    }

    .search_user {
      width: 180px;
      border-radius: 40px;
    }
  }

  .icon {
    border-left: 1px solid #e4e4e4;
    text-align: center;
  }
}
</style>